@import-normalize;

@font-face {
  font-family: "DIN Condensed Bold";
  src: local("DIN Condensed Bold"),
    url("./assets/DIN-Condensed-Bold.ttf") format("truetype"),
    url("./assets/DIN-Condensed-Bold.woff") format("woff");
}

@font-face {
  font-family: "Lato Black";
  src: local("Lato Black"),
    url("./assets/Lato-Black.ttf") format("truetype")
}

@font-face {
  font-family: "Lato Bold";
  src: local("Lato Bold"),
    url("./assets/Lato-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
    url("./assets/Lato-Regular.ttf") format("truetype")
}

h1, h4, h5, p {
  margin: 0;
}

a {
  font-family: 'Lato Bold';
  text-decoration: underline;
  cursor: pointer;
}

button {
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  font-size: inherit;
  font-family: 'Lato Bold';
  padding: 0;
}

body {
  margin: 0;
  font-family: Lato;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background-color: #121712;
  box-sizing: border-box;
}

.bold {
  font-family: 'Lato Bold';
}

.banner__wrapper {
  padding: 54px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: 303px;
  background-color: #475546;
  color: #ffffff;

  h1 {
    font-family: "DIN Condensed Bold";
    font-weight: 400;
    font-size: 5rem;
  }

  h2 {
    margin-top: 2px;
    margin-bottom: 32px;
    font-family: "Lato";
    font-size: 1.125rem;
    text-transform: uppercase;
    position: relative;
    left: 2px;
  }
}

.banner__description {
  padding: 44px 30px 50px;
  background-color: #F0F0F0;
  text-align: center;

  & > p {
    line-height: 24px;
    font-size: 1.125rem;
    margin-bottom: 24px;
  }

  & > p:last-child {
    margin-bottom: 35px;
  }
}

main {
  padding: 51px 20px;
  text-align: center;

  h4 {
    font-family: "DIN Condensed Bold";
    font-weight: 400;
    font-size: 8rem;
    color: #574D41;
    margin-bottom: 20px;
  }

  h5 {
    margin-bottom: 1.5625rem;
    font-family: "Lato Black";
    font-size: 1.5rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 24px;
  }

  img {
    margin-bottom: 20px;
    max-width: 100%;
  }
}

.benefit {
  margin-bottom: 50px;
}

.benefits {
  max-width: 850px;
  margin: 0 auto 24px;
}

.accordion-wrapper {
  display: flex;
  justify-content: center;
  scroll-margin: 20px;
}

.MuiAccordion-region p {
  margin-bottom: 15px;
  font-size: .8750rem;
  line-height: 1.050rem;
  font-family: 'Lato';

  &:last-child {
    margin-bottom: 0;
  }
}

.MuiAccordionSummary-content {
  justify-content: center;
}

.accordion-summary {
  min-height: auto;
  justify-content: center;
}

.MuiAccordionSummary-expandIconWrapper img {
  margin: 0;
}

.partner-store-discounts {
  font-family: 'Lato Bold';
  font-size: 1rem;
  text-align: left;
  margin: 16px 0;

  & ~ span {
    font-size: .8750rem;
    display: block;
    text-align: left;
  }

  & ~ p {
    font-size: .8750rem;
    margin-bottom: 0;
  }
}

@media all and (min-width: 1440px) {
  header {
    height: 96px;
    padding-left: 63px;
    justify-content: flex-start;
  }

  p,
  .banner__description > p {
    font-size: 1.5rem;
    line-height: 32px;
  }

  .banner__description > p:last-of-type {
    margin-bottom: 70px;
  }

  .banner__wrapper {
    flex-direction: row;
    justify-content: center;

    h1 {
      font-size: 11.25rem;
    }

    h2 {
      font-size: 1.5rem;
      left: 6px;
    }

    img {
      width: 342px;
    }

    & > div {
      // margin-right: 157px;
    }
  }

  main {
    padding: 100px 20px 51px;

    h4 {
      margin-bottom: 50px;
    }

    h5 {
      margin-bottom: 50px;
      font-family: 'Lato Bold';
      font-size: 4rem;
    }
  }

  .benefits {
    max-width: 1000px;
    margin: 0 auto 50px;
  }

  .benefit {
    margin-bottom: 100px;

    & > img {
      width: 600px;
      margin-bottom: 50px;
    }
  }

  .banner__description {
    padding: 44px 76px 100px;
  }

  .break {
    display: block;
  }

  .MuiAccordion-region p {
    font-size: 1.125rem;
    line-height: 1.3rem;
  }
}